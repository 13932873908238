import {
  postBillingAddress,
  postShippingAddress,
} from '@backmarket/http-api/src/api-specs-after-sale-experience/client/shipping-address'
import { postBuyBackAddress } from '@backmarket/http-api/src/api-specs-buyback/customer/postAddress'
import {
  postUpdateCollectionPointCustomerDetails,
  postUpdateUserInformation,
} from '@backmarket/http-api/src/api-specs-checkout/cart/cart'
import type {
  Bill,
  BillableCountry,
  CartResponse,
  Deliver,
  DeliverCollectionPoint,
  ShippableCountry,
} from '@backmarket/http-api/src/api-specs-checkout/cart/cart.types'
import { Country } from '@backmarket/http-api/src/standards/Country'
import { $httpFetch } from '@backmarket/nuxt-module-http/$httpFetch'
import { isEmpty } from '@backmarket/utils/object/isEmpty'
import { isEqual } from '@backmarket/utils/object/isEqual'
import { pick } from '@backmarket/utils/object/pick'
import { removeEmptyValuesInObject } from '@backmarket/utils/object/removeEmptyValuesInObject'
import { defineStore } from 'pinia'

import { FORM_TYPES } from '../types/form'
import { cleanAddressState } from '../utils/cleanAddressState'
import { saveBouyguesAddress } from '../utils/saveBouyguesAddress'

interface AddressStore {
  shipping: Deliver
  billing: Bill
  collectionPoint: DeliverCollectionPoint | undefined
  shippableCountries: ShippableCountry[]
  billableCountries: BillableCountry[]
}

const addUserInformation = async ({
  address,
  formType,
}: {
  address: Partial<Deliver | Bill>
  formType: (typeof FORM_TYPES)[keyof typeof FORM_TYPES]
}) => {
  const userInformationBody = {
    birthdate: address.birthdate,
    ...(address.customerIdNumber && {
      taxId: address.customerIdNumber,
    }),
  }

  await $httpFetch(postUpdateUserInformation, {
    body: { ...userInformationBody, formType },
  })
}

export const useAddressStore = defineStore('addressStore', {
  state: (): AddressStore => ({
    shipping: {
      birthdate: '',
      city: '',
      company: '',
      country: '' as Country,
      countryDialInCode: '',
      customerIdNumber: '',
      dateCreation: '',
      dateModification: '',
      email: '',
      firstName: '',
      firstNamePronunciation: '',
      lastName: '',
      lastNamePronunciation: '',
      phone: '',
      postalCode: '',
      stateOrProvince: '',
      street: '',
      street2: '',
      warnings: [],
    },
    billing: {
      birthdate: '',
      city: '',
      company: '',
      country: '' as Country,
      countryDialInCode: '',
      customerIdNumber: '',
      dateCreation: '',
      dateModification: '',
      email: '',
      firstName: '',
      firstNamePronunciation: '',
      lastName: '',
      lastNamePronunciation: '',
      phone: '',
      postalCode: '',
      stateOrProvince: '',
      street: '',
      street2: '',
    },
    collectionPoint: undefined,
    shippableCountries: [],
    billableCountries: [],
  }),
  getters: {
    isShippingAddressComplete: ({ shipping = {} }) => {
      return (
        !isEmpty(shipping) &&
        !isEmpty(`${shipping.firstName} ${shipping.lastName}`.trim()) &&
        !isEmpty(shipping.street?.trim()) &&
        !isEmpty(shipping.postalCode?.trim()) &&
        !isEmpty(shipping.city?.trim()) &&
        !isEmpty(shipping.country) &&
        !isEmpty(shipping.countryDialInCode) &&
        !isEmpty(shipping.phone)
      )
    },
    isBillingAddressComplete: ({ billing = {} }) => {
      return (
        !isEmpty(billing) &&
        !isEmpty(`${billing.firstName} ${billing.lastName}`.trim()) &&
        !isEmpty(billing.street?.trim()) &&
        !isEmpty(billing.postalCode?.trim()) &&
        !isEmpty(billing.city?.trim()) &&
        !isEmpty(billing.country)
      )
    },
    hasCollectionPoint: (state) => !isEmpty(state.collectionPoint),
    isCollectionPointAddressComplete: ({ collectionPoint }) => {
      return (
        !isEmpty(collectionPoint) &&
        !isEmpty(collectionPoint.firstName) &&
        !isEmpty(collectionPoint.lastName) &&
        !isEmpty(collectionPoint.countryDialInCode) &&
        !isEmpty(collectionPoint.phone)
      )
    },
    areShippingAndBillingTheSame: ({ shipping, billing }) => {
      const fieldsToCompare: Array<string> = [
        'firstName',
        'lastName',
        'street',
        'street2',
        'postalCode',
        'city',
        'country',
      ]

      if (isEmpty(shipping) || isEmpty(billing)) {
        return false
      }

      return isEqual(
        pick(billing, fieldsToCompare as Array<keyof AddressStore['billing']>),
        pick(
          shipping,
          fieldsToCompare as Array<keyof AddressStore['shipping']>,
        ),
      )
    },
  },
  actions: {
    setAddress({
      shippableCountries,
      billableCountries,
      addressList,
    }: CartResponse) {
      this.shipping = {
        ...addressList.deliver,
        customerIdNumber: addressList.bill?.customerIdNumber ?? '',
      }

      this.billing = addressList.bill
      this.collectionPoint =
        addressList.deliverCollectionPoint as DeliverCollectionPoint
      this.shippableCountries = shippableCountries
      this.billableCountries = billableCountries
    },
    async saveAddress({
      isShipping,
      isBilling,
      formType,
      address,
    }: {
      isShipping: boolean
      isBilling: boolean
      formType: (typeof FORM_TYPES)[keyof typeof FORM_TYPES] | undefined
      address: Deliver | Bill
    }) {
      // 0. Clean states for addresses that doesn't have one
      const cleanedAddress = removeEmptyValuesInObject(address)

      // 1. Shipping and/or billing address
      if (isShipping && isBilling) {
        await Promise.all([
          $httpFetch(postShippingAddress, { body: cleanedAddress }),
          $httpFetch(postBillingAddress, { body: cleanedAddress }),
        ])
      } else {
        if (isShipping)
          await $httpFetch(postShippingAddress, { body: cleanedAddress })
        if (isBilling)
          await $httpFetch(postBillingAddress, { body: cleanedAddress })
      }

      // 2. User information (needed only if insurance)
      const shouldUpdateUserInfo = formType === FORM_TYPES.INSURANCE_ONLY

      if (shouldUpdateUserInfo) {
        await addUserInformation({ address: cleanedAddress, formType })
      }
    },
    async saveBouyguesAddress({
      address,
      isShipping,
      isBilling,
    }: {
      address: Deliver | Bill
      isShipping: boolean
      isBilling: boolean
    }) {
      if (isShipping && isBilling) {
        await saveBouyguesAddress(address, 'BILLING_AND_SHIPPING')
      } else {
        await saveBouyguesAddress(address, isShipping ? 'SHIPPING' : 'BILLING')
      }
    },
    async createBuybackAddress(address: Deliver) {
      const cleanedAddress = cleanAddressState(address)

      await $httpFetch(postBuyBackAddress, {
        body: { ...cleanedAddress },
      })
    },
    async saveCollectionPoint({
      formType,
      address,
    }: {
      formType: (typeof FORM_TYPES)[keyof typeof FORM_TYPES] | undefined
      address: Partial<Deliver | Bill>
    }) {
      await $httpFetch(postUpdateCollectionPointCustomerDetails, {
        body: {
          countryDialInCode: address.countryDialInCode,
          firstName: address.firstName,
          lastName: address.lastName,
          phone: address.phone,
        },
      })

      // 2. User information (needed only if insurance)
      if (formType === FORM_TYPES.INSURANCE_ONLY) {
        await addUserInformation({ address, formType })
      }
    },
  },
})
